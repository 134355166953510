const firebaseConfig = {
    apiKey: "AIzaSyCIWTpOWg__uQ_E8MT_m8u-2KA50-NX644",
    authDomain: "where-is-waldo-ca911.firebaseapp.com",
    projectId: "where-is-waldo-ca911",
    storageBucket: "where-is-waldo-ca911.appspot.com",
    messagingSenderId: "551789358988",
    appId: "1:551789358988:web:a366d7fbbeae177e5a70a9",
    measurementId: "G-85XSHYR3WP"
  };

export function getFirebaseConfig() {
    if (!firebaseConfig || !firebaseConfig.apiKey) {
        // eslint-disable-next-line no-useless-concat
        throw new Error('No Firebase configuration object provided.' + '\n' +
        'Add your web app\'s configuration object to firebase-config.js');
    } else {
        return firebaseConfig;
    }
}