import React from 'react';
import '../styling/Introduction.css';

const Introduction = () => {

    return (
        <div className="introduction">
            <h1>How to play</h1>
            <div>1. Locate required character</div>
            <div>2. Click on him</div>
            <div>3. Select from new menu which character you think you found</div>
        </div>
    );
}

export default Introduction;
